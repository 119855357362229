import styled from 'styled-components';

export const HamburgerToggleWrapper = styled.div`
    z-index: 2;
    display: block;

    color: white;
    height: 20px;
    width: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;

    cursor: pointer;

    span:nth-child(1) {
        top: ${props => (props.$isOpen ? `-1.5px;` : '0px;')}
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;

        ${props =>
            props.$isOpen &&
            `
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                top: -1.5px;
                left: 4px;
                `}
    }

    span:nth-child(2) {
        top: 10px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;

        ${props =>
            props.$isOpen &&
            `
                width: 0%;
                opacity: 0;
            `}
    }

    span:nth-child(3) {
        top: ${props => (props.$isOpen ? `19.5px; ` : `20px;`)}
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;

        ${props =>
            props.$isOpen &&
            `
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                top: 19.5px;
                left: 4px;
            `}
    }
`;

export const HamburgerToggleSpan = styled.span`
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    border-radius: 9px;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
`;
