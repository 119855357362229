import styled from 'styled-components';

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${props => (props.$column ? 'column' : 'row')};
    justify-content: ${props => (props.$content ? props.$content : 'normal')};
    align-items: ${props => (props.$align ? props.$align : 'normal')};
    gap: ${props => (props.$gap ? props.$gap : '1em')};
    flex-wrap: ${props => (props.$wrap ? 'wrap' : 'no-wrap')};

    @media (max-width: 500px) {
        flex-direction: column;
    }
`;
