import styled from 'styled-components';

export const MobileNavBarWrapper = styled.div`
    display: none;

    @media (max-width: 700px) {
        display: block;
    }
`;

export const MobileNavBarUl = styled.ul`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    ${props => (props.$isOpen ? `height: 100%;` : `height: 0%;`)}
    width: 100%;

    overflow: hidden;

    transition: all 0.5s ease;

    z-index: 1;

    list-style: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;

    margin: auto;

    @media only screen and (max-height: 500px) and (max-width: 700px) {
        gap: 25px;
    }
`;

export const MobileNavBarOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    ${props => (props.$isOpen ? `height: 100%;` : `height: 0%;`)}
    width: 100%;
    background: #0d0d0d;
    opacity: 0.9;
    z-index: 1;
    display: block;
    transition: all 0.5s ease;
`;
