import React from 'react';

import { FitContentSectionWrapper } from '../../ui/wrappers';
import { TitleWrapper } from '../../ui/titles';
import { H3 } from '../../ui/headings';
import { GridContainer } from '../../ui/grid';

import { projects } from '../../constants';

import ProjectCard from './ProjectCard';
import { FlexContainer } from '../../ui/flex';

const Projects = () => (
    <FitContentSectionWrapper id='projects'>
        <FlexContainer $column $content='center' $gap='5em'>
            <TitleWrapper>
                <H3>Projects</H3>
            </TitleWrapper>
            <GridContainer>
                {projects.map((project, idx) => {
                    if (idx === 0 || idx % 2 === 0) {
                        return (
                            <ProjectCard
                                key={idx}
                                project={project}
                                right={true}
                            />
                        );
                    }
                    return <ProjectCard key={idx} project={project} />;
                })}
            </GridContainer>
        </FlexContainer>
    </FitContentSectionWrapper>
);

export default Projects;
