import React from 'react';

import { H4, P } from '../../ui/headings';
import { FlexContainer } from '../../ui/flex';
import {
    ProjectCardDescriptionWrapper,
    ProjectCardImg,
    ProjectCardInnerWrapper,
    ProjectCardLine,
    ProjectCardTagLi,
    ProjectCardTagUl,
    ProjectCardTextWrapper,
    ProjectCardTitleWrapper,
    ProjectCardWrapper,
    ProjectLinksLi,
    ProjectLinksUl
} from '../../ui/projects/project-card';

import { pickTextColorBasedOnBgColor } from '../../utils/pick-text-color-based-on-bgcolor';
import { lightenDarkenColor } from '../../utils/lighten-darken-color';

import ProjectLink from './ProjectLink';

const ProjectCard = ({ project, right = false }) => (
    <ProjectCardWrapper>
        <ProjectCardInnerWrapper>
            <ProjectCardImg
                $right={right}
                src={project.image}
                alt='Portfolio-v2 logo'
                color={project.color}
            />
            <ProjectCardTextWrapper $right={right}>
                <ProjectCardTitleWrapper>
                    <H4>{project.title}</H4>
                </ProjectCardTitleWrapper>
                <ProjectLinksUl>
                    {project.links.map((link, idx) => (
                        <ProjectLinksLi key={idx}>
                            <ProjectLink link={link} />
                        </ProjectLinksLi>
                    ))}
                </ProjectLinksUl>
                <ProjectCardLine color={project.color}></ProjectCardLine>
                <ProjectCardDescriptionWrapper>
                    <P>{project.description}</P>
                    <FlexContainer $content='space-between'>
                        <ProjectCardTagUl>
                            {project.technologies.map((tech, idx) => (
                                <ProjectCardTagLi
                                    key={idx}
                                    color={pickTextColorBasedOnBgColor(
                                        project.color
                                    )}
                                    $bgColor={project.color}
                                    $hoverBgColor={`#${lightenDarkenColor(
                                        project.color
                                    )}`}
                                >
                                    {tech}
                                </ProjectCardTagLi>
                            ))}
                        </ProjectCardTagUl>
                    </FlexContainer>
                </ProjectCardDescriptionWrapper>
            </ProjectCardTextWrapper>
        </ProjectCardInnerWrapper>
    </ProjectCardWrapper>
);

export default ProjectCard;
