import styled from 'styled-components';

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${props => (props.$gap ? props.$gap : '3em')};

    @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        column-gap: 3em;
    }

    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        width: 100%;
        column-gap: 3em;
    }
`;
