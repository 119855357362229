import React from 'react';
import NavLinks from './NavLinks';
import {
    DesktopNavBarUl,
    DesktopNavBarWrapper
} from '../../ui/navbar/desktop-navbar';

const DesktopNavBar = () => {
    return (
        <DesktopNavBarWrapper>
            <DesktopNavBarUl>
                <NavLinks />
            </DesktopNavBarUl>
        </DesktopNavBarWrapper>
    );
};

export default DesktopNavBar;
