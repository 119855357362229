import React from 'react';

import { H1, H2 } from '../ui/headings';
import { FullSectionWrapper } from '../ui/wrappers';

import { insertSocialItems } from '../utils/insert-social-items';

const Home = () => (
    <FullSectionWrapper id='home'>
        <div className='animated-title'>
            <div className='text-top'>
                <div>
                    <H1>Hello,</H1>
                    <H1>I'm Reito Serizawa</H1>
                </div>
            </div>
            <div className='text-bottom'>
                <H2>Fullstack Software Engineer</H2>
                {insertSocialItems({ home: true })}
            </div>
        </div>
    </FullSectionWrapper>
);

export default Home;
