import React from 'react';
import {
    HamburgerToggleSpan,
    HamburgerToggleWrapper
} from '../../ui/navbar/hamburger-toggle';

const HamburgerToggle = ({ isOpen, setIsOpen }) => (
    <HamburgerToggleWrapper onClick={() => setIsOpen(!isOpen)} $isOpen={isOpen}>
        <HamburgerToggleSpan></HamburgerToggleSpan>
        <HamburgerToggleSpan></HamburgerToggleSpan>
        <HamburgerToggleSpan></HamburgerToggleSpan>
    </HamburgerToggleWrapper>
);

export default HamburgerToggle;
