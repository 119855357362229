import styled from 'styled-components';

export const TextWrapper = styled.div`
    flex-basis: 60%;

    display: flex;
    flex-direction: column;
    gap: 30px;

    margin: auto;

    @media (max-width: 1024px) {
        flex-basis: 100%;
    }
`;

export const ImgWrapper = styled.div`
    flex-basis: 40%;

    display: flex;
    justify-content: center;

    @media (max-width: 1024px) {
        position: absolute;
        top: 100px;
        right: 0px;
        height: fit-content;
        width: 25%;
        z-index: -1;
    }

    @media (max-width: 500px) {
        position: static;
        margin: auto;
    }
`;
