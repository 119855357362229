import React from 'react';
import {
    KeySkillContainer,
    KeySkillIconImg,
    KeySkillName,
    KeySkillWrapper
} from '../../ui/contact-me/key-skill';
import { keySkills } from '../../constants';

const KeySkill = () => (
    <KeySkillContainer>
        {keySkills.map((skill, idx) => (
            <KeySkillWrapper key={idx}>
                <KeySkillIconImg src={skill.logo} alt={skill.name} />

                <KeySkillName>{skill.name}</KeySkillName>
            </KeySkillWrapper>
        ))}
    </KeySkillContainer>
);

export default KeySkill;
