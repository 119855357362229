import styled from 'styled-components';

export const ContentContainer = styled.div`
    padding: 15px;
    display: flex;
    flex-flow: row wrap;
`;

export const ProjectContainer = styled.div`
    padding-right: 15px;
    width: 70%;

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        padding-right: 0;
    }
`;

export const KeySkillOuterContainer = styled.div`
    width: 30%;

    @media (max-width: 1024px) {
        width: 100%;
        margin-top: 15px;
    }
`;

export const ProfileSectionTitle = styled.div`
    text-transform: uppercase;
    color: #aaa;
    letter-spacing: 1.25px;
    font-size: 13.2px;
    margin-bottom: 10px;
`;

export const ProfileHeaderContainer = styled.div`
    height: 320px;
    border-bottom: 1px solid #282828;
    position: relative;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/7022/spotify_img_bground.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.15;
        background-image: url(https://media.istockphoto.com/id/1387616822/photo/tokyo-skyline-with-mt-fuji.webp?b=1&s=170667a&w=0&k=20&c=BAPw-__GBDT9_sKEoJATqkWghl5FTb4Gghya7z76AC4=);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
    }

    @media (max-width: 522px) {
        height: auto;
        flex-flow: column wrap;
    }
`;

export const ProfileInfoContainer = styled.div`
    padding: 15px;
    z-index: 1;
    width: 80%;
    margin-top: 78px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;

    @media (max-width: 522px) {
        margin-top: 0;
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        text-align: center;
    }
`;

export const ProfileImgWrapper = styled.div`
    position: relative;
    margin-right: 15px;
    @media (max-width: 522px) {
        margin-right: 0;
    }
`;

export const ProfileImg = styled.img`
    height: 150px;
    width: 150px;
    border-radius: 50%;

    @media (max-width: 768px) {
        height: 100px;
        width: 100px;
    }
`;

export const Name = styled.h4`
    color: #fff;
    font-size: 36px;
    font-weight: 100;
    padding: 0 0 10px 0;
`;

export const Role = styled.p`
    color: #aaa;
    text-transform: uppercase;
    font-size: 13px;

    @media (min-width: 522px) {
        margin-top: 10px;
    }
`;

export const CityContainer = styled.div`
    width: 20%;
    z-index: 1;
    padding: 15px;
    text-align: right;
    color: #aaa;

    @media (max-width: 522px) {
        width: 100%;
        text-align: center;
    }
`;

export const CityP = styled.p`
    text-transform: uppercase;
    font-size: 12px;
`;

export const ProfileContainer = styled.div`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #333;
`;
