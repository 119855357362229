import styled, { keyframes } from 'styled-components';

const showTitle = keyframes`
    0% {
        transform: translateY(150%);
    }
    100% {
        transform: translateY(0%);
    }
`;

export const TitleWrapper = styled.div`
    position: relative;
    display: inline-block;

    width: fit-content;
    overflow: hidden;

    padding-bottom: 1vh;

    border-bottom: 3px solid white;

    h3 {
        animation: ${showTitle} 1s;
        animation-fill-mode: forwards;
    }
`;
