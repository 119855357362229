export const lightenDarkenColor = (col, amt = -20) => {
    if (col[0] === '#') {
        col = col.slice(1);
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    var b = ((num >> 8) & 0x00ff) + amt;
    var g = (num & 0x0000ff) + amt;
    var newColor = g | (b << 8) | (r << 16);
    return newColor.toString(16);
};
