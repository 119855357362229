import styled from 'styled-components';

export const SkillCardWrapper = styled.div`
    position: relative;
    padding: 20px;
    height: 380px;
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    background-color: white;

    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
    }

    &:hover header {
        height: 120px;
    }

    &:hover header img {
        transform: translateX(-50%) translateY(-50%) scale(3);
    }

    &:hover div {
        height: 260px;
    }

    &:hover div ul li {
        transform: translateX(0);
        transition-timing-function: cubic-bezier(0, 1.5, 1, 1);
        transition-duration: 0.5s;
    }

    ${() => {
        let liTransitionDelay = '';
        for (let i = 1; i <= 8; i++) {
            liTransitionDelay += `&:hover div ul li:nth-child(${i}) {
                transition-delay: ${i * 0.25}s;
            }`;
        }

        return liTransitionDelay;
    }}

    @media (max-width: 1024px) {
        width: calc((100% - 80px) / 3);
    }

    @media (max-width: 768px) {
        width: calc((100% - 80px) / 2);
        margin: auto;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const SkillCardHeader = styled.header`
    position: relative;
    height: 320px;
    margin: -20px -20px 20px -20px;
    transition: height 0.5s;
    background-color: ${({ color }) => color};
    overflow: hidden;
`;

export const SkillCardImg = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: block;
    width: 128px;
    height: 128px;
    transition: transform 0.5s;
`;

export const SkillCardBody = styled.div`
    height: 45px;
    transition: height 0.5s;
    overflow: hidden;
`;

export const SkillCardTitle = styled.h3`
    text-transform: uppercase;
    color: #666;
    display: flex;
    justify-content: space-between;
`;

export const SkillCardUl = styled.ul`
    list-style-type: disc;
    padding-top: 10px;
    color: #424242;
    display: flex;
    flex-direction: column;
`;

export const SkillCardLi = styled.li`
    text-align: left;
    list-style: disc outside none;
    display: list-item;
    margin-left: 28px;
    margin-top: 5px;
    transition-duration: 0;
    transition-delay: 0.5s;
    transform: translateX(-100%);
    color: #333;
`;
