import React from 'react';
import { SocialContainer, SocialA } from '../../ui/contact-me/social';
import { socials } from '../../constants';

const Social = () => (
    <SocialContainer>
        {socials.map((social, idx) => (
            <SocialA
                key={idx}
                $dark={idx === 0}
                href={social.link}
                target='_blank'
                rel='noreferrer'
            >
                {social.name}
            </SocialA>
        ))}
    </SocialContainer>
);

export default Social;
