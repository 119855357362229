import React, { useState } from 'react';

import { AppWrapper } from '../ui/wrappers';

import NavBar from './NavBar';
import Particle from './Particle';
import Home from './Home';
import AboutMe from './AboutMe';
import Skills from './Skills';
import Projects from './Projects';
import ContactMe from './ContactMe';

function App() {
    const [particleInit, setParticleInit] = useState(false);

    return (
        <>
            <Particle
                particleInit={particleInit}
                setParticleInit={setParticleInit}
            />
            {particleInit && (
                <AppWrapper>
                    <NavBar />
                    <Home />
                    <AboutMe />
                    <Skills />
                    <Projects />
                    <ContactMe />
                </AppWrapper>
            )}
        </>
    );
}

export default App;
