import React from 'react';

import { Dot, DotWrapper, LoaderWrapper } from '../ui/loader';

const Loader = () => (
    <LoaderWrapper>
        <DotWrapper>
            <Dot></Dot>
            <Dot></Dot>
            <Dot></Dot>
        </DotWrapper>
    </LoaderWrapper>
);
export default Loader;
