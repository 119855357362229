import React, { useState } from 'react';
import NavLinks from './NavLinks';
import HamburgerToggle from './HamburgerToggle';
import {
    MobileNavBarOverlay,
    MobileNavBarUl,
    MobileNavBarWrapper
} from '../../ui/navbar/mobile-navbar';

const MobileNavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <MobileNavBarWrapper>
            <MobileNavBarOverlay $isOpen={isOpen}></MobileNavBarOverlay>
            <HamburgerToggle isOpen={isOpen} setIsOpen={setIsOpen} />
            <MobileNavBarUl $isOpen={isOpen}>
                <NavLinks closeMobileNav={() => setIsOpen(false)} />
            </MobileNavBarUl>
        </MobileNavBarWrapper>
    );
};

export default MobileNavBar;
