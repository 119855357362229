import React from 'react';
import DesktopNavBar from './DesktopNavBar';
import icon from '../../images/header-logo-white.png';
import MobileNavBar from './MobileNavBar';
import {
    NavBarIcon,
    NavBarIconWrapper,
    NavBarWrapper
} from '../../ui/navbar/navbar';

const NavBar = () => {
    return (
        <NavBarWrapper>
            <NavBarIconWrapper>
                <NavBarIcon src={icon} alt='Nav bar icon'></NavBarIcon>
            </NavBarIconWrapper>
            <DesktopNavBar />
            <MobileNavBar />
        </NavBarWrapper>
    );
};

export default NavBar;
