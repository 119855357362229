import styled from 'styled-components';

export const DesktopNavBarWrapper = styled.div`
    display: block;

    @media (max-width: 700px) {
        display: none;
    }
`;

export const DesktopNavBarUl = styled.ul`
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 50px;

    list-style: none;
`;
