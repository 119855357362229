import { socials } from '../constants';
import { SocialLi, SocialUl } from '../ui/social';

export const insertSocialItems = ({ home = false, color = null }) => {
    const socialItemsLi = socials.map((social, idx) => (
        <SocialLi key={idx}>
            <a href={social?.link} target='_blank' rel='noreferrer'>
                <i className={social?.icon}></i>
            </a>
        </SocialLi>
    ));

    return (
        <SocialUl $home={home} color={color}>
            {socialItemsLi}
        </SocialUl>
    );
};
