import React from 'react';
import { navItems } from '../../constants';
import { NavAnchor } from '../../ui/navbar/navbar';

const NavLinks = ({ closeMobileNav = () => {} }) => {
    const insertNavLinks = navItems => {
        return navItems.map((item, idx) => {
            return (
                <li key={idx}>
                    <NavAnchor href={item?.path} onClick={closeMobileNav}>
                        {item?.name}
                    </NavAnchor>
                </li>
            );
        });
    };

    return insertNavLinks(navItems);
};

export default NavLinks;
