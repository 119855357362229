import styled from 'styled-components';

export const FullSectionWrapper = styled.section`
    position: relative;

    height: 100vh;
    width: 100%;

    padding-top: 70px;
`;

export const FitContentSectionWrapper = styled.section`
    position: relative;

    height: fit-content;
    width: 100%;

    padding-top: 100px;
`;

export const AppWrapper = styled.div`
    color: #fff;

    height: calc(100vh-70px);

    min-width: auto;
    max-width: 1000px;

    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
`;
