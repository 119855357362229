import styled from 'styled-components';

export const LatestProjectWrapper = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-flow: row wrap;
`;

export const LatestProjectInfoWrapper = styled.div`
    width: calc(100% - 75px);
    padding: 10px 15px;
    background: #282828;
    color: #aaa;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
`;

export const LatestProjectTitle = styled.div`
    color: #c8c8c8;
`;

export const LatestProjectIconImgWrapper = styled.div`
    width: 75px;
    height: 75px;
`;

export const LatestProjectIconImg = styled.img`
    width: 100%;
    height: 100%;
`;
