import styled from 'styled-components';

export const H1 = styled.h1`
    display: block;
    font-size: 6em;

    @media (max-width: 1024px) {
        font-size: 5em;
    }

    @media (max-width: 768px) {
        font-size: 3.3em;
    }

    @media (max-width: 500px) {
        font-size: 1.9em;
    }
`;

export const H2 = styled.h2`
    display: block;
    font-size: 3em;

    @media (max-width: 1024px) {
        font-size: 2.5em;
    }

    @media (max-width: 768px) {
        font-size: 1.75em;
    }

    @media (max-width: 500px) {
        font-size: 1.2em;
    }
`;

export const H3 = styled.h3`
    display: block;
    font-size: 4em;

    @media (max-width: 1024px) {
        font-size: 3.3em;
    }

    @media (max-width: 768px) {
        font-size: 3em;
    }

    @media (max-width: 500px) {
        font-size: 2.5em;
    }
`;

export const H4 = styled.h2`
    display: block;
    font-size: 1.75em;

    @media (max-width: 1024px) {
        font-size: 1.5em;
    }

    @media (max-width: 768px) {
        font-size: 1.25em;
    }

    @media (max-width: 500px) {
        font-size: 1.1em;
    }
`;

export const H5 = styled.h2`
    display: block;
    font-size: 1.3em;
    font-weight: 100;

    @media (max-width: 1024px) {
        font-size: 1.2em;
    }

    @media (max-width: 768px) {
        font-size: 1.1em;
    }

    @media (max-width: 500px) {
        font-size: 1em;
    }
`;

export const P = styled.p`
    font-size: 1em;
`;
