import styled from 'styled-components';

export const NavBarIconWrapper = styled.div`
    z-index: 2;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
`;

export const NavBarIcon = styled.img`
    height: 35px;
`;

export const NavBarWrapper = styled.nav`
    height: 70px;

    font-weight: 100;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto;
    min-width: auto;
    max-width: 1000px;

    padding-left: 15px;
    padding-right: 34px;

    color: white;
    background-color: #282c34;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    z-index: 2;
`;

export const NavAnchor = styled.a`
    position: relative;
    cursor: pointer;

    li {
        margin-right: 20px;
    }

    li:last-child {
        margin-right: 0px;
    }

    &:before,
    &:after {
        position: absolute;
        opacity: 0;
        top: 0px;
        transition: all 0.3s;
    }

    &:before {
        content: '<';
        left: -20px;
        transform: translateX(10px);
    }

    &:after {
        content: ' />';
        right: -20px;
        transform: translateX(-10px);
    }

    &:hover:before,
    &:hover:after {
        opacity: 1;
        transform: translateX(0px);
    }

    &.active:before,
    &.active:after {
        opacity: 1;
        transform: translateX(0px);
    }

    @media (max-width: 700px) {
        font-size: 30px;
        font-weight: 400;

        &:before {
            left: -50px;
        }

        &:after {
            right: -50px;
        }
    }

    @media only screen and (max-height: 500px) and (max-width: 700px) {
        font-size: 20px;
    }
`;
