import React from 'react';
import {
    OtherProjectsContainer,
    OtherProjectWrapper,
    ProjectDate,
    ProjectIconImg,
    ProjectTag,
    ProjectTagWrapper,
    ProjectTitle
} from '../../ui/contact-me/other-project';
import { projects } from '../../constants';

const OtherProject = () => (
    <OtherProjectsContainer>
        {projects.slice(1).map((project, idx) => (
            <OtherProjectWrapper key={idx}>
                <ProjectIconImg src={project.image} alt={project.title} />

                <ProjectTitle>{project.title}</ProjectTitle>

                <ProjectTagWrapper>
                    <ProjectTag>{project.tag}</ProjectTag>
                </ProjectTagWrapper>

                <ProjectDate>{project.date}</ProjectDate>
            </OtherProjectWrapper>
        ))}
    </OtherProjectsContainer>
);

export default OtherProject;
