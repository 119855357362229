import styled, { keyframes } from 'styled-components';

export const LoaderWrapper = styled.div`
    background-color: #282c34;
    display: grid;
    place-items: center;
    min-height: 100vh;
`;

export const DotWrapper = styled.div`
    position: relative;

    span:nth-child(1) {
        left: -1.5rem;
    }

    span:nth-child(2) {
        animation-delay: -1s;
    }

    span:nth-child(3) {
        right: -2.5rem;
        animation-delay: -0.8s;
    }
`;

const bounce = keyframes`
    0%,
    66%,
    100% {
        transform: initial;
    }

    33% {
        transform: translatey(-1rem);
    }
`;

export const Dot = styled.span`
    position: absolute;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: #fff;
    animation: ${bounce} 1.3s linear infinite;
`;
