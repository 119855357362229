import React from 'react';
import {
    LatestProjectIconImg,
    LatestProjectIconImgWrapper,
    LatestProjectInfoWrapper,
    LatestProjectTitle,
    LatestProjectWrapper
} from '../../ui/contact-me/latest-project';
import { P } from '../../ui/headings';
import { projects } from '../../constants';

const LatestProject = () => {
    const project = projects[0];

    return (
        <LatestProjectWrapper>
            <LatestProjectIconImgWrapper>
                <LatestProjectIconImg
                    src={project?.image}
                    alt={project?.title}
                />
            </LatestProjectIconImgWrapper>

            <LatestProjectInfoWrapper>
                <LatestProjectTitle>{project?.title}</LatestProjectTitle>
                <P>{project?.date}</P>
            </LatestProjectInfoWrapper>
        </LatestProjectWrapper>
    );
};

export default LatestProject;
