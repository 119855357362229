import styled from 'styled-components';

export const OtherProjectsContainer = styled.div`
    display: flex;
    flex-flow: column wrap;

    &:last-child {
        border-bottom: 1px solid #282828;
    }
`;

export const OtherProjectWrapper = styled.div`
    border-top: 1px solid #282828;
    height: 42px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    &:hover {
        background: #282828;
    }

    @media (max-width: 1200px) {
        height: auto;
        padding: 10px 0;
    }
`;

export const ProjectIconImg = styled.img`
    width: 42px;
    height: 42px;
`;

export const ProjectTitle = styled.p`
    width: 45%;
    margin-left: 30px;
    color: #fff;

    @media (max-width: 1200px) {
        width: auto !important;
    }
`;

export const ProjectTagWrapper = styled.div`
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const ProjectTag = styled.span`
    border: 1px;
    border-style: solid;
    border-color: #626262;
    color: #626262;
    text-transform: uppercase;
`;

export const ProjectDate = styled.p`
    color: #aaa;
    margin-left: auto;
    padding-right: 10px;
`;
