import React, { useEffect } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';

import Loader from './Loader';

const Particle = ({ particleInit, setParticleInit }) => {
    useEffect(() => {
        initParticlesEngine(async engine => {
            await loadSlim(engine);
        }).then(() => {
            setParticleInit(true);
        });
    }, [setParticleInit]);

    if (!particleInit) return <Loader />;

    return (
        <Particles
            fullScreen={{ enable: true, zIndex: 0 }}
            id='tsparticles'
            options={{
                background: {
                    color: '#282c34'
                },
                particles: {
                    number: {
                        value: 100
                    },
                    move: {
                        direction: 'vertical',
                        enable: true,
                        outMode: 'bounce',
                        random: true,
                        speed: 0.1,
                        straight: false
                    },
                    opacity: {
                        animation: {
                            enable: true,
                            speed: 1,
                            sync: false
                        },
                        value: { min: 0, max: 1 }
                    },
                    size: {
                        value: { min: 1, max: 3 }
                    }
                }
            }}
        />
    );
};

export default Particle;
