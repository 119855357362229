import React from 'react';

import {
    SkillCardBody,
    SkillCardHeader,
    SkillCardImg,
    SkillCardLi,
    SkillCardTitle,
    SkillCardUl,
    SkillCardWrapper
} from '../../ui/skills/skill-card';

const SkillCard = ({ skillItem = {} }) => (
    <SkillCardWrapper>
        <SkillCardHeader color={skillItem?.color}>
            <SkillCardImg src={skillItem?.image} alt='Backend logo' />
        </SkillCardHeader>
        <SkillCardBody>
            <SkillCardTitle>
                {skillItem?.name}
                <i className={skillItem?.icon}></i>
            </SkillCardTitle>
            <SkillCardUl>
                {skillItem?.skills.map((skill, idx) => (
                    <SkillCardLi key={idx}>{skill}</SkillCardLi>
                ))}
            </SkillCardUl>
        </SkillCardBody>
    </SkillCardWrapper>
);

export default SkillCard;
