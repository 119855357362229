import React from 'react';

import profileIcon from '../../images/contact-icon.png';

import { FitContentSectionWrapper } from '../../ui/wrappers';
import { TitleWrapper } from '../../ui/titles';
import { H3 } from '../../ui/headings';
import { FlexContainer } from '../../ui/flex';
import {
    CityContainer,
    CityP,
    ContentContainer,
    KeySkillOuterContainer,
    Name,
    ProfileContainer,
    ProfileHeaderContainer,
    ProfileImg,
    ProfileImgWrapper,
    ProfileInfoContainer,
    ProfileSectionTitle,
    ProjectContainer,
    Role
} from '../../ui/contact-me/contact-me';

import OtherProject from './OtherProject';
import KeySkill from './KeySkill';
import LatestProject from './LatestProject';
import Social from './Social';

const ContactMe = () => {
    return (
        <FitContentSectionWrapper
            id='contact-me'
            style={{ marginBottom: '5em' }}
        >
            <FlexContainer $column $content='center' $gap='5em'>
                <TitleWrapper>
                    <H3>Contact Me</H3>
                </TitleWrapper>

                <ProfileContainer>
                    <ProfileHeaderContainer>
                        <ProfileInfoContainer>
                            <ProfileImgWrapper>
                                <ProfileImg src={profileIcon} alt='G-Eazy' />
                            </ProfileImgWrapper>

                            <div>
                                <Role>Fullstack Software Engineer</Role>
                                <Name>Reito Serizawa</Name>
                                <Social />
                            </div>
                        </ProfileInfoContainer>

                        <CityContainer>
                            <CityP>Jersey City, NJ</CityP>
                            <CityP>New York, NY</CityP>
                        </CityContainer>
                    </ProfileHeaderContainer>

                    <ContentContainer>
                        <ProjectContainer>
                            <ProfileSectionTitle>
                                Latest Project
                            </ProfileSectionTitle>
                            <LatestProject />

                            <ProfileSectionTitle>
                                Other Projects
                            </ProfileSectionTitle>
                            <OtherProject />
                        </ProjectContainer>

                        <KeySkillOuterContainer>
                            <ProfileSectionTitle>
                                Programming Languages
                            </ProfileSectionTitle>

                            <KeySkill />
                        </KeySkillOuterContainer>
                    </ContentContainer>
                </ProfileContainer>
            </FlexContainer>
        </FitContentSectionWrapper>
    );
};

export default ContactMe;
