import styled from 'styled-components';

export const SKillMacWindowCardWrapper = styled.div`
    flex: 1;
    text-align: center;

    padding: 30px;

    display: flex;
    flex-direction: column;
    gap: 2em;

    i {
        font-size: 3em;
    }
`;

export const SKillMacWindowCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    items-align: center;
    flex-wrap: wrap;

    div:nth-child(1) {
        border-right: 1px solid black;
    }

    div:nth-child(2) {
        margin-left: -1px;
        border-right: 1px solid black;
    }

    div:nth-child(3) {
        margin-left: -1px;
    }

    @media (max-width: 768px) {
        div {
            flex: 0 50%;
        }

        div:nth-child(2) {
            border-right: none;
        }

        div:nth-child(3) {
            margin: auto;
        }
    }

    @media (max-width: 500px) {
        div {
            flex: 0 100%;
            margin: auto;
        }

        div:nth-child(1) {
            border-right: none;
        }
    }
`;
