import React from 'react';

import aboutMe from '../../images/about-me.png';

import { H3, H4, P } from '../../ui/headings';
import { TitleWrapper } from '../../ui/titles';
import { FlexContainer } from '../../ui/flex';
import { Img } from '../../ui/img';
import { FitContentSectionWrapper } from '../../ui/wrappers';
import { ImgWrapper, TextWrapper } from '../../ui/about-me/about-me';

import MoreAboutMeMacWindow from './MoreAboutMeMacWindow';

const AboutMe = () => (
    <FitContentSectionWrapper id='about-me'>
        <FlexContainer $column $content='center' $gap='5em'>
            <TitleWrapper>
                <H3>About me</H3>
            </TitleWrapper>
            <FlexContainer $gap='3em'>
                <TextWrapper>
                    <H4>
                        I am Reito. I am a bilingual full-stack software
                        engineer in NYC. I build complex, scalable web
                        applications.
                    </H4>
                    <P>
                        Growing up, I was always interested in different
                        cultures and languages. I studied abroad in Australia
                        and the U.S. and decided to work as a sales professional
                        in Hong Kong and Tokyo.
                    </P>
                    <P>
                        Since I immigrated to the U.S., I have thought about my
                        career. When I was in junior high school, I enjoyed
                        coding and making a website for flip phones. Though my
                        interests at the time were cultures and languages, I
                        didn't have a chance to study coding.
                    </P>
                    <P>
                        After I moved to NYC, I decided to pursue the dream of
                        being a software engineer and studied software
                        engineering at Flatiron School. It was the best choice
                        in my life. I love what I do, and I am passionate about
                        self-development.
                    </P>
                </TextWrapper>
                <ImgWrapper>
                    <Img src={aboutMe} alt='memoji' />
                </ImgWrapper>
            </FlexContainer>
            <MoreAboutMeMacWindow />
        </FlexContainer>
    </FitContentSectionWrapper>
);

export default AboutMe;
