import React from 'react';

const ProjectLink = ({ link, withName = true }) => {
    switch (link.name) {
        case 'Live':
            return (
                <a href={link.link} target='_blank' rel='noreferrer'>
                    {withName && link.name}
                    <i className='fa-regular fa-window-maximize'></i>
                </a>
            );
        case 'Demo':
            return (
                <a href={link.link} target='_blank' rel='noreferrer'>
                    {withName && link.name}
                    <i className='fa-solid fa-video'></i>
                </a>
            );
        case 'Code':
            return (
                <a href={link.link} target='_blank' rel='noreferrer'>
                    {withName && link.name}
                    <i className='fa-brands fa-github'></i>
                </a>
            );
        case 'Article':
            return (
                <a href={link.link} target='_blank' rel='noreferrer'>
                    {withName && link.name}
                    <i className='fa-solid fa-newspaper'></i>
                </a>
            );
        default:
            return;
    }
};

export default ProjectLink;
