import React from 'react';

import {
    SKillMacWindowCardContainer,
    SKillMacWindowCardWrapper
} from '../../ui/skills/skill-mac-window';
import { H4, P } from '../../ui/headings';
import MacWindow from '../MacWindow';
import { skillMacWindowItems } from '../../constants';

const SkillMacWindow = () => (
    <MacWindow title="i'm different!">
        <SKillMacWindowCardContainer>
            {skillMacWindowItems.map((skillMacWindowItem, idx) => (
                <SKillMacWindowCardWrapper key={idx}>
                    <i className={skillMacWindowItem?.icon}></i>
                    <H4>{skillMacWindowItem?.title}</H4>
                    <P>{skillMacWindowItem?.paragraph}</P>
                </SKillMacWindowCardWrapper>
            ))}
        </SKillMacWindowCardContainer>
    </MacWindow>
);

export default SkillMacWindow;
