import React from 'react';

import { TitleWrapper } from '../../ui/titles';
import { H3 } from '../../ui/headings';
import { FitContentSectionWrapper } from '../../ui/wrappers';
import { FlexContainer } from '../../ui/flex';

import { skillItems } from '../../constants';

import SkillCard from './SkillCard';
import SkillMacWindow from './SkillMacWindow';

const Skills = () => (
    <FitContentSectionWrapper id='skills'>
        <FlexContainer $column $content='center' $gap='5em'>
            <TitleWrapper>
                <H3>Skills</H3>
            </TitleWrapper>
            <FlexContainer
                $content='space-between'
                $align='center'
                $wrap
                $gap='30px'
                className='skill-card-container'
            >
                {skillItems.map((skillItem, idx) => (
                    <SkillCard key={idx} skillItem={skillItem} />
                ))}
            </FlexContainer>
            <SkillMacWindow />
        </FlexContainer>
    </FitContentSectionWrapper>
);

export default Skills;
