import styled from 'styled-components';

export const TagUl = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #a6a6a6;
    li {
        color: #666;
        margin: 4px 3px;
        border-radius: 5px;
        background: #f2f2f2;
        padding: 5px 8px 5px 10px;
        border: 1px solid #e3e1e1;
    }
`;

export const TagLi = styled.li`
    color: #666;
    margin: 4px 3px;
    border-radius: 5px;
    background: #f2f2f2;
    padding: 5px 8px 5px 10px;
    border: 1px solid #e3e1e1;
`;
