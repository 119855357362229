import styled from 'styled-components';

export const SocialContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

export const SocialA = styled.a`
    margin-right: 10px;
    height: 27px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0 15px;
    font-weight: 500;
    border-radius: 20px;
    border: none;
    padding: 5px 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    outline: none;

    background: ${({ $dark }) => ($dark ? '#1ed760' : 'none')};
    color: ${({ $dark }) => ($dark ? '#fff' : '#c8c8c8')};
    border: ${({ $dark }) =>
        $dark ? '1px solid #1ed760' : '1px solid #c8c8c8'};

    &:hover {
        cursor: pointer;
        ${({ $dark }) =>
            $dark ? 'background: #43e57d;' : 'border-color: #fff; color: #fff;'}
    }
`;
