import styled from 'styled-components';

export const MacWindowHeaderWrapper = styled.div`
    background: #b4b4b4;
    opacity: 0.9;
    width: 100%;

    padding: 10px;
    border-radius: 7px 7px 0px 0px;

    display: flex;

    div:nth-child(1) {
        flex: 1;
    }

    div:nth-child(2) {
        text-align: center;
    }

    div:nth-child(3) {
        flex: 1;
    }
`;

export const MacWindowDotWrapper = styled.div`
    div:nth-child(1) {
        background: #ff6057;
        border: 1px solid #e14640;
    }

    div:nth-child(2) {
        background: #ffbd2e;
        border: 1px solid #dfa123;
    }

    div:nth-child(3) {
        background: #27c93f;
        border: 1px solid #1dad2b;
    }
`;

export const MacWindowDot = styled.div`
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #f9f9f9;
    border-radius: 50%;
    margin: 0 4px 0 0;
`;

export const MacWindowBody = styled.div`
    height: fit-contnet;
    background: #f9f9f9;

    margin-top: -5em;
    padding: 30px;

    color: #333;

    border-radius: 0px 0px 7px 7px;
`;
