import React, { Fragment } from 'react';
import MacWindow from '../MacWindow';
import { FlexContainer } from '../../ui/flex';
import { P } from '../../ui/headings';
import { TagLi, TagUl } from '../../ui/about-me/more-about-me-mac-window';
import { insertSocialItems } from '../../utils/insert-social-items';
import { aboutMeTags } from '../../constants';

const MoreAboutMeMacWindow = () => (
    <MacWindow title='more about me...'>
        <FlexContainer $column>
            {aboutMeTags?.map((item, idx) => {
                return (
                    <Fragment key={idx}>
                        <P>{item.paragraph}</P>
                        <TagUl>
                            {item.tags.map((tag, idx) => (
                                <TagLi key={idx}>{tag}</TagLi>
                            ))}
                        </TagUl>
                    </Fragment>
                );
            })}
            {insertSocialItems({ color: '#333' })}
        </FlexContainer>
    </MacWindow>
);

export default MoreAboutMeMacWindow;
