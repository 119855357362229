import styled from 'styled-components';

export const KeySkillContainer = styled.div`
    display: flex;
    flex-flow: column wrap;

    @media (max-width: 1024px) {
        flex-flow: row wrap;
    }
`;

export const KeySkillWrapper = styled.div`
    background: #282828;
    padding: 5px;
    margin-bottom: 2px;

    display: flex;
    align-items: center;

    &:hover {
        background: #373737;
        text-decoration: none;
    }

    @media (max-width: 1024px) {
        margin: 10px;
        width: calc((100% / 3) - 20px);
    }

    @media (max-width: 768px) {
        margin: 5px;
        width: calc((100% / 2) - 10px);
    }

    @media (max-width: 480px) {
        margin: 5px;
        width: 20%;
        background: none;

        &:hover {
            background: none;
            opacity: 0.6;
        }
    }
`;

export const KeySkillIconImg = styled.img`
    width: 42px;
    height: 42px;
    border-radius: 50%;
`;

export const KeySkillName = styled.span`
    margin-left: 15px;
    color: #fff;

    @media (max-width: 480px) {
        display: none;
    }
`;
