import styled from 'styled-components';

export const SocialUl = styled.ul`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 0px;

    li {
        color: ${({ color }) => (color ? color : 'white')};
        margin-right: 10px;
    }

    li:last-child {
        margin-right: 0px;
    }

    li a {
        color: ${({ color }) => (color ? color : 'white')};
    }

    ${props =>
        props.$home &&
        `font-size: 3em;

        @media (max-width: 1024px) {
            font-size: 2.5em;
        }

        @media (max-width: 768px) {
            font-size: 1.75em;
            li {
                margin-right: 5px;
            }
        }

        @media (max-width: 500px) {
            margin-top: 1.3em;
            font-size: 1.25em;
        }
    `}
`;

export const SocialLi = styled.li`
    a {
        transition: all 0.25s;
    }

    &:hover a {
        display: inline-block;
        opacity: 0.5;
        margin: 0px;
        transform: translateY(-15%);
    }
`;
