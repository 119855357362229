import styled from 'styled-components';

export const ProjectCardWrapper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    transition: transform 0.2s ease;
    overflow: hidden;

    &:hover {
        transform: translate(0px, -3px);
    }
    &:hover div img {
        transform: scale(1.05) rotate(1deg);
    }

    &:hover div div div:nth-child(3) {
        width: 70px;
    }

    @media (min-width: 1025px) {
        position: relative;
        width: 100%;
        height: 350px;
    }
`;

export const ProjectCardInnerWrapper = styled.div`
    @media (min-width: 1025px) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;

export const ProjectCardImg = styled.img`
    transition: transform 0.2s ease;
    object-fit: contain;
    background-color: ${({ color }) => (color ? color : 'transparent')};

    @media (min-width: 1025px) {
        position: absolute;
        height: 350px;
        width: 400px;
        top: 0;
        left: 0;

        ${({ $right }) =>
            $right &&
            `
        left: initial;
        right: 0;
    `}
    }

    @media (max-width: 1024px) {
        width: 100%;
        height: 250px;
    }
`;

export const ProjectCardTextWrapper = styled.div`
    * {
        position: relative;
    }

    @media (min-width: 1025px) {
        position: absolute;
        top: 7%;
        bottom: 7%;
        left: 430px;
        width: calc(100% - 470px);

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            background: #fff;
            top: -20%;
            left: -55px;
            height: 140%;
            width: 60px;
            transform: rotate(8deg);
        }

        &::after {
            display: none;
            left: initial;
            right: -55px;
        }

        ${({ $right }) =>
            $right &&
            `
            left: initial;
            right: 430px;

            &::before {
                display: none;
            }

            &::after {
                display: block;
            }
        `}
    }

    @media (max-width: 1024px) {
        padding: 15px;
    }
`;

export const ProjectCardTitleWrapper = styled.div`
    color: #333;
`;

export const ProjectLinksUl = styled.ul`
    display: flex;
    color: #888;
    padding: 0.2em 0;

    li {
        padding: 0px 10px;
        border-right: 1px solid black;
    }
    li:first-child {
        padding-left: 0px;
    }
    li:last-child {
        padding-right: 0px;
        border-right: none;
    }
`;

export const ProjectLinksLi = styled.li`
    font-size: 1.1em;
    transition: all 0.2s;

    a {
        transition: all 0.2s;
        color: #888;
    }

    i {
        margin-left: 5px;
    }

    &:hover {
        transform: translateY(-10%);
    }

    &:hover a {
        color: #555;
    }

    @media (max-width: 1024px) {
        font-size: 1em;
        i {
            visibility: visible;
        }
    }
`;

export const ProjectCardLine = styled.div`
    left: -2px;
    width: 50px;
    height: 5px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: ${({ color }) => (color ? color : '#424242')};
    transition: width 0.2s ease;
`;

export const ProjectCardDescriptionWrapper = styled.div`
    min-height: 225px;
    max-height: 350px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: #333;
`;

export const ProjectCardTagUl = styled.ul``;

export const ProjectCardTagLi = styled.li`
    cursor: pointer;
    position: relative;

    display: inline-block;
    background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : '#333')};
    color: ${({ color }) => (color ? color : '#fff')};
    border-radius: 3px;
    padding: 3px 10px 3px 10px;
    margin-top: 5px;
    margin-right: 20px;
    user-select: none;
    text-decoration: none;

    &:hover {
        background: ${({ $hoverBgColor }) =>
            $hoverBgColor ? $hoverBgColor : '#888'}
`;
