import React from 'react';

import {
    MacWindowBody,
    MacWindowDot,
    MacWindowDotWrapper,
    MacWindowHeaderWrapper
} from '../ui/mac-window';

const MacWindow = ({ title = '', children }) => (
    <>
        <MacWindowHeaderWrapper>
            <MacWindowDotWrapper>
                <MacWindowDot></MacWindowDot>
                <MacWindowDot></MacWindowDot>
                <MacWindowDot></MacWindowDot>
            </MacWindowDotWrapper>
            <div>{title}</div>
            <div></div>
        </MacWindowHeaderWrapper>
        <MacWindowBody>{children}</MacWindowBody>
    </>
);

export default MacWindow;
